import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {
  Grid,
  Typography,
  ButtonBase,
  Divider,
  CircularProgress,
  useMediaQuery,
  Theme,
} from '@material-ui/core'
import { Link } from '@src/components/Link'
import { productDetailRoute, useRouter } from '@src/routes'
import clsx from 'clsx'
import { ValidMaterialPricingDetailFragment } from '@src/fragments/ProductPricing.generated'
import ClearIcon from '@material-ui/icons/Clear'
import HandleMarkup from '@src/components/HandleMarkup'
import MaterialPricingDisplay from '@src/components/MaterialPricingDisplay'
import messages from '@utils/messages'
import { useIntl } from 'react-intl'
import { MaterialsDetailFragment } from '@src/queries/MaterialDetailsQuery.generated'
import {
  sendQuickOrderTracking,
  sendQuickOrderInteractionEvent,
} from '@src/utils/analytics'
import { sendPricingAvailabilityInteractionEvent } from '@src/utils/analytics/pricingAndAvailability'
import { alignSupSub } from '@src/styles/utils/alignSupSub'
import MaterialAvailabilityDisplay from '@src/components/MaterialAvailabilityDisplay'
import { Abilities, useAbility } from '@src/utils/useAbility'
import { InitialShipEstimate } from '@src/components/ProductPriceAvailability/ProductPriceAvailabilityFormBody'
import { MaterialAvailabilityKey } from '@src/types/graphql-types'
import { TrackedAvailabilityMessageProvider } from '@src/components/TrackedAvailabilityMessage/TrackedAvailabilityMessageContext'

const useStyles = makeStyles((theme: Theme) => ({
  manualEntryColumnContainer: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(4),
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  },
  manualEntryColumn: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  manualEntryColumnPricing: {
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(2),
    textAlign: 'end',
  },
  linkText: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightBold,
  },
  availabilityMessages: {
    '& > *': {
      fontSize: theme.typography.pxToRem(14),
      borderTop: 'none',
      padding: 0,
      lineHeight: 1.4,
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  textAlignEnd: {
    textAlign: 'end',
  },
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    display: 'block',
    [theme.breakpoints.up('xs')]: {
      whiteSpace: 'nowrap',
    },
  },
  alignSupSub,
  price: {
    fontSize: theme.typography.pxToRem(14),
  },
  listPrice: {
    fontSize: theme.typography.pxToRem(12),
    lineHeight: 1,
    textDecoration: 'line-through',
    color: theme.palette.grey[700],
  },
  progressSpinner: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  divider: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
    },
    [theme.breakpoints.up('xs')]: {
      marginBottom: theme.spacing(1),
    },
    width: '100%',
  },
  closeButtonMobile: {
    position: 'relative',
    top: 0,
    right: 16,
    justifyContent: 'flex-end',
  },
  closeButtonDesktop: {
    justifyContent: 'flex-start',
  },
  clearRowButton: {
    backgroundColor: theme.palette.background.default,
    border: `2px solid transparent`,
    borderRadius: '50%',
    '&.Mui-focusVisible': {
      border: `2px solid transparent`,
    },
  },
  priceContainer: {
    marginLeft: theme.spacing(2),
  },
  buttonLink: {
    paddingTop: theme.spacing(1),
  },
  itemStyles: {
    padding: theme.spacing(2),
  },
}))

interface ManualEntryRowProps {
  material: MaterialsDetailFragment | null | undefined
  priceData: ValidMaterialPricingDetailFragment | null | undefined
  loading?: boolean
  error?: unknown
  handleClearRow: (rowMaterialId: string, rowQuantityId: string) => void
  rowMaterialId: string
  rowQuantityId: string
  source?: string
}

const ManualEntryRow: React.FC<ManualEntryRowProps> = ({
  material,
  priceData,
  loading,
  error,
  handleClearRow,
  rowMaterialId,
  rowQuantityId,
  source,
}) => {
  const router = useRouter()
  const catalog = router?.query?.catalog
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))
  const { authorized: canAddToCart } = useAbility(Abilities.canAddToCart)
  const { formatMessage } = useIntl()

  if (loading && !priceData && !error)
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.progressSpinner}
      >
        <CircularProgress size={20} />
      </Grid>
    )

  if (priceData === undefined || error) return null

  const materialNumber = priceData?.materialNumber || ''
  const itemName = material?.name || ''
  const brand = priceData?.brand || ''
  const productKey = material?.product || ''
  const [availability] = priceData?.availabilities || []
  const isInquireForPA =
    availability &&
    availability?.key === MaterialAvailabilityKey.InquireForPriceAndAvailability

  const displayInquireLink = availability && availability.displayInquireLink

  return (
    <>
      <TrackedAvailabilityMessageProvider
        source={source || 'quick order'}
        brand={brand}
        item={materialNumber}
        pricing={priceData}
      >
        <Grid
          container
          className={clsx({
            [classes.manualEntryColumnContainer]: true,
          })}
          justifyContent="space-between"
          spacing={isMobile ? 0 : 4}
          direction={isMobile ? 'column' : 'row'}
        >
          <Grid className={classes.manualEntryColumn} item xs="auto" sm={2}>
            <Typography className={classes.linkText}>
              {!productKey ? (
                materialNumber
              ) : (
                <Link
                  {...productDetailRoute.index(brand, productKey, {
                    catalog,
                  })}
                  passHref
                >
                  {/* eslint-disable-next-line */}
                  <a
                    onClick={() =>
                      sendQuickOrderTracking(
                        'manual entry p&a bar - open pdp',
                        materialNumber,
                        {
                          clickType: 'quick order',
                          clickAction: 'open pdp - manual entry p&a bar',
                          clickDetails: `${materialNumber}`,
                          component: 'action form',
                          elementType: 'link',
                          linkText: `${materialNumber}`,
                          linkUrl: `${
                            productDetailRoute.index(brand, productKey, {
                              catalog,
                            }).as
                          }`,
                        }
                      )
                    }
                  >
                    {materialNumber}
                  </a>
                </Link>
              )}
            </Typography>
          </Grid>
          <Grid item className={classes.manualEntryColumn} xs="auto" sm={3}>
            <div className={clsx(classes.truncate, classes.alignSupSub)}>
              <HandleMarkup value={itemName} />
            </div>
          </Grid>
          <Grid
            className={clsx(
              classes.manualEntryColumn,
              isInquireForPA ? classes.buttonLink : classes.itemStyles
            )}
            item
            xs={isMobile || isInquireForPA ? 'auto' : 4}
          >
            {priceData ? (
              <MaterialAvailabilityDisplay
                canAddToCart={canAddToCart}
                material={priceData}
                initialShipEstimate={
                  <InitialShipEstimate availability={availability} />
                }
                gaDetailsClick={() =>
                  sendPricingAvailabilityInteractionEvent({
                    action: 'check availability details',
                    detail: 'manual entry',
                    section: source || 'quick order',
                    component: 'list',
                    elementType: 'link',
                    elementText: 'details',
                    material: priceData,
                  })
                }
                source={source || 'quick order'}
                gaSection={'quick order'}
                gaComponent={'list'}
              />
            ) : null}
          </Grid>
          <Grid
            className={clsx({
              [classes.buttonLink]: displayInquireLink,
              [classes.manualEntryColumnPricing]: true,
              [classes.priceContainer]: !isMobile,
            })}
            item
            xs={isMobile ? 'auto' : 1}
          >
            {priceData && !isInquireForPA ? (
              <MaterialPricingDisplay
                material={priceData}
                priceClass={classes.price}
                listPriceClass={classes.listPrice}
                hasStackedPrices={isMobile ? false : true}
              />
            ) : (
              ''
            )}
          </Grid>
          <Grid
            className={clsx({
              [classes.manualEntryColumn]: true,
              [classes.closeButtonMobile]: isMobile,
              [classes.closeButtonDesktop]: !isMobile,
            })}
            item
            xs={12}
            sm="auto"
          >
            <ButtonBase
              title={formatMessage(messages.REMOVE)}
              aria-label={formatMessage(messages.REMOVE)}
              data-testid="delete-input-button"
              className={classes.clearRowButton}
              onClick={() => {
                handleClearRow(rowMaterialId, rowQuantityId)
                sendQuickOrderInteractionEvent(
                  {
                    action: 'remove item',
                    detail: 'manual entry',
                    component: 'list',
                    elementType: 'icon',
                  },
                  {
                    eventCategory: 'quick order page',
                    eventAction: 'manual entry p&a bar - remove',
                    eventLabel: materialNumber,
                    eventInteractionType: 0,
                  },
                  {
                    productId: productKey,
                    productBrand: brand,
                    productVariant: materialNumber,
                    productName: itemName,
                  }
                )
              }}
            >
              <ClearIcon />
            </ButtonBase>
          </Grid>
        </Grid>
      </TrackedAvailabilityMessageProvider>
      <Divider className={classes.divider} />
    </>
  )
}

export default ManualEntryRow
