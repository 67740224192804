import { Grid, Link as MuiLink, Theme, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import LiquidMaterialAutoSuggestAdapter from '@src/components/LiquidMaterialAutoSuggestAdapter'
import LiquidQuantityInputAdapter from '@src/components/LiquidQuantityInputAdapter'
import vrStyles from '@src/styles/utils/vrStyles'
import {
  DiscontinuedMaterialPricingDetailFragment,
  ProductPricingDetailFragment,
} from '@src/fragments/ProductPricing.generated'
import messages from '@utils/messages'
import { Field, FieldArray, FormikProps, getIn } from 'formik'
import React, { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { sendQuickOrderPandAErrorEvent } from '@utils/analytics/pricingAndAvailability'
import { useQuickOrder } from '@src/components/QuickOrder/utils/quickOrderContext'
import { useLatest } from 'react-use'

import { defaultMaterial, QuickOrderFormValues } from './QuickOrder'
import ManualEntryRow from './ManualEntryRow'
import { MaterialsDetailFragment } from '@src/queries/MaterialDetailsQuery.generated'
import { QueryResult } from '@src/utils/QueryResult'
import { MultipleProductEntryFormValues } from '@src/components/QuickOrder/MultipleProductEntry'
import { sendPricingAvailabilityInteractionEvent } from '@src/utils/analytics/pricingAndAvailability'
import { useUserSession } from '@src/utils/useUserSession'
import { support } from '@src/routes'

const { vr1 } = vrStyles
const useStyles = makeStyles((theme: Theme) => ({
  addIcon: {
    fontSize: theme.typography.pxToRem(18),
  },
  actionsGroup: {
    marginTop: theme.spacing(3),
  },
  addRows: {
    cursor: 'pointer',
    paddingInline: 'initial',
    justifyContent: 'initial',
    '&.MuiButton-root.Mui-disabled': {
      cursor: 'auto',
      color: theme.palette.text.disabled,
    },
  },
  formRow: {
    ...vr1,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 0,
    },
  },
  addItemsWrapper: {
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
  },
  replaceLink: {
    cursor: 'pointer',
  },
  manualInputField: {
    height: 38,
  },
}))

interface ManualEntryProps {
  formikBag:
    | FormikProps<QuickOrderFormValues>
    | FormikProps<MultipleProductEntryFormValues>
  pricingErrors: {
    [productNumber: string]: DiscontinuedMaterialPricingDetailFragment
  }
  pricing: Partial<{
    [materialNumber: string]: QueryResult<ProductPricingDetailFragment>
  }>
  materials: Partial<{
    [materialNumber: string]: QueryResult<MaterialsDetailFragment>
  }>
  setModalOpen: React.Dispatch<
    React.SetStateAction<ProductPricingDetailFragment | null>
  >
  onBlur?: () => void
  source?: string
}

const ManualEntry: React.FC<ManualEntryProps> = ({
  formikBag,
  pricingErrors,
  pricing,
  materials,
  setModalOpen,
  onBlur,
  source,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { formatMessage } = useIntl()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm')) === true
  const size = isDesktop ? 'small' : 'large'
  const {
    userSession: { country },
  } = useUserSession()

  useEffect(() => {
    sendQuickOrderPandAErrorEvent(pricingErrors)
  }, [pricingErrors])

  const materialInputsRef = useRef<Partial<{ [id: string]: HTMLInputElement }>>(
    {}
  )
  const materialInnerRef =
    (id: string) => (inputEl: HTMLInputElement | null) => {
      if (!inputEl) delete materialInputsRef.current[id]
      else materialInputsRef.current[id] = inputEl
    }
  const clearRowHandler = (rowMaterialId: string, rowQuantityId: string) => {
    formikBag.setFieldValue(rowMaterialId, '')
    formikBag.setFieldValue(rowQuantityId, 1)
    materialInputsRef.current[rowMaterialId]?.querySelector('input')?.focus()
  }

  const replacementProductLink = (
    materialNumber,
    showReplacementProducts = true,
    displaySimilarProductLabel = false
  ) => (
    <>
      {formatMessage(messages.PRODUCT_DISCONTINUED, {
        materialNumber: materialNumber,
      })}
      {showReplacementProducts && (
        <span>
          {' '}
          <MuiLink
            className={classes.replaceLink}
            onClick={() => setModalOpen(pricing[materialNumber]?.data || null)}
          >
            {formatMessage(
              messages[
                displaySimilarProductLabel
                  ? 'VIEW_SIMILAR_PRODUCTS'
                  : 'VIEW_REPLACEMENT_PRODUCTS'
              ]
            )}
          </MuiLink>
        </span>
      )}
    </>
  )

  const getOfflineChannelsMessage = (): React.ReactNode => {
    const messages = {
      US: {
        message: {
          id: 'PRODUCT_SOLD_EXCLUSIVELY_BY_MILLIPORE_VIA_OFFLINE_CHANNELS',
          defaultMessage:
            'This product is sold exclusively by Millipore via offline channels. {link}',
        },
        contactService: {
          id: 'CONTACT_CUSTOMER_SERVICE_FIRST_UPPERCASE',
          defaultMessage: 'Contact Customer Service',
        },
      },
      CA: {
        message: {
          id: 'PRODUCT_ONLY_SOLD_VIA_OFFLINE_CHANNELS',
          defaultMessage:
            'This product is only sold via offline channels. {link}',
        },
        contactService: {
          id: 'PLEASE_CONTACT_CUSTOMER_SERVICE',
          defaultMessage: 'Please Contact Customer Service',
        },
      },
    }

    const messageData = messages[country]

    if (messageData) {
      const contactCustomerServiceText = formatMessage(
        messageData.contactService
      )

      return formatMessage(messageData.message, {
        link: (
          <a href={support.customerSupport()}>{contactCustomerServiceText}</a>
        ),
      })
    }

    return null
  }

  useEffect(() => {
    const { status, setStatus, values } = formikBag
    !status && setStatus({})

    // For each input, check if a pricing error exists
    const statusObj = {}
    values.materials.forEach((material, index) => {
      statusObj[`materials[${index}].materialNumber`] = undefined
      const pricingError = pricingErrors[material.materialNumber]
      if (pricingError) {
        const errorMsg = pricingError.errorMsg
        const values = {
          productNumber: material.materialNumber,
        }

        // Some error messages have additional data that needs to be passed into formatMessage
        if (errorMsg === 'CUSTOMER_NUMBER_BLOCKED') {
          Object.assign(values, {
            customerNumber: pricingError.paramList
              ? pricingError.paramList[0]
              : '',
          })
        }
        if (errorMsg === 'EMD_MILLIPORE_PRICING_AND_INVENTORY_UNAVAILABLE') {
          Object.assign(values, {
            link: (
              <a
                href="https://www.emdmillipore.com"
                target="_blank"
                rel="noreferrer"
              >
                Millipore
              </a>
            ),
          })
        }
        if (errorMsg === 'MERCK_MILLIPORE_PRICING_AND_INVENTORY_UNAVAILABLE') {
          Object.assign(values, {
            link: (
              <a
                href="https://merckmillipore.com"
                target="_blank"
                rel="noreferrer"
              >
                Merck Millipore
              </a>
            ),
          })
        }

        const showReplacementProducts = !(
          !pricingError.replacementProducts?.length ||
          pricingError.hideReplacementProductLink
        )

        statusObj[`materials[${index}].materialNumber`] =
          errorMsg === 'EMD_MILLIPORE_PRICING_AND_INVENTORY_UNAVAILABLE'
            ? getOfflineChannelsMessage()
            : errorMsg === 'UNABLE_TO_GET_RESPONSE_FROM_PI'
              ? formatMessage(messages.PRICING_AND_AVAILABILITY_UNAVAILABLE)
              : errorMsg === 'PRICING_AND_AVAILABILITY_UNAVAILABLE'
                ? formatMessage(messages.THIS_PRODUCT_NUMBER_DOES_NOT_EXIST)
                : errorMsg === 'THIS_PRODUCT_HAS_BEEN_DISCONTINUED'
                  ? replacementProductLink(
                      material.materialNumber,
                      showReplacementProducts,
                      !!pricingError.displaySimilarProductLabel
                    )
                  : messages[errorMsg]
                    ? formatMessage(messages[errorMsg], values)
                    : messages[errorMsg]
      }
    })

    setStatus(statusObj)
    // TODO: need a test before we refactor these dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pricingErrors, formikBag.values])

  const { addItems, resetPendingItems, mergedQuickOrderItems } = useQuickOrder()
  const onBlurRef = useLatest(onBlur) // uses latest onBlur value without re-triggering effects

  useEffect(() => {
    if (addItems) {
      formikBag.setFieldValue(
        'materials',
        mergedQuickOrderItems(formikBag.values.materials, defaultMaterial)
      )
      resetPendingItems()
      // setTimeout ensures onBlur callback uses latest form values https://github.com/jaredpalmer/formik/issues/529#issuecomment-1462897295
      setTimeout(() => {
        if (onBlurRef.current) onBlurRef.current()
      }, 0)
    }
    // only want this to run when addItems is updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addItems])

  if (!formikBag?.values) return null

  return (
    <FieldArray name="materials" validateOnChange={false}>
      {() =>
        formikBag.values.materials.map((material, index) => (
          <Grid item xs={12} key={index} className={classes.formRow}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={10}>
                <Field
                  className={classes.manualInputField}
                  name={`materials[${index}].materialNumber`}
                  id={`materials[${index}].materialNumber`}
                  innerRef={materialInnerRef(
                    `materials[${index}].materialNumber`
                  )}
                  component={LiquidMaterialAutoSuggestAdapter}
                  placeholder={formatMessage(messages.PRODUCT_NUMBER_PACK_SIZE)}
                  filled
                  error={getIn(
                    formikBag.status,
                    `materials[${index}].materialNumber`
                  )}
                  onChange={(value: string) => {
                    formikBag.setFieldValue(
                      `materials[${index}].materialNumber`,
                      value.toUpperCase()
                    )
                  }}
                  onBlur={onBlur}
                  onSelect={(item) => {
                    if (onBlur) onBlur()
                    formikBag.setFieldTouched(
                      `materials[${index}].materialNumber`
                    )
                    sendPricingAvailabilityInteractionEvent({
                      action: 'view p&a',
                      detail: 'manual entry',
                      section: source ? source : 'quick order',
                      component: 'autosuggest',
                      material: {
                        materialDescription: item?.substanceKey,
                        brand: item?.brandKey,
                        product: item?.productKey,
                        materialNumber: item?.label,
                      },
                    })
                  }}
                  source={source}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <Field
                  className={classes.manualInputField}
                  name={`materials[${index}].quantity`}
                  component={LiquidQuantityInputAdapter}
                  min={1}
                  max={9999}
                  size={size}
                  inputProps={{
                    'data-testid': `materials[${index}].quantity-input`,
                    id: `materials[${index}].quantity-input`,
                  }}
                  filled
                  associatedComponent={`materials[${index}].quantity-input`}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12}>
              <ManualEntryRow
                source={source}
                error={pricingErrors[material.materialNumber]}
                loading={
                  pricing[material.materialNumber]?.loading ||
                  materials[material.materialNumber]?.loading
                }
                material={materials[material.materialNumber]?.data}
                priceData={
                  pricing[material.materialNumber]?.data?.materialPricing[0]
                }
                handleClearRow={clearRowHandler}
                rowMaterialId={`materials[${index}].materialNumber`}
                rowQuantityId={`materials[${index}].quantity`}
              />
            </Grid>
          </Grid>
        ))
      }
    </FieldArray>
  )
}

export default ManualEntry
