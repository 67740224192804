import getConfig from 'next/config'
import {
  Button,
  Divider,
  Grid,
  useMediaQuery,
  Link as MUILink,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import WarningIcon from '@material-ui/icons/Warning'
import CircleLoader from '@src/components/CircleLoader'
import {
  SnackbarType,
  useGlobalSnackbar,
  SnackbarMessages,
} from '@src/components/GlobalSnackbar/globalSnackBarContext'
import { MaterialsInput } from '@src/components/QuickOrder/Types'
import {
  formatQuickOrderBulkUploadEntry,
  parseMaterialsInput,
  parseQuickOrderSpreadsheet,
  useBulkUploadValidation,
} from '@src/components/QuickOrder/utils'
import ReplacementProductsModal from '@src/components/ReplacementProductsModal'
import SelectDtaqDealerModal from '@src/components/SelectDtaqDealerModal'
import {
  cartRoute,
  useRouter,
  staticContent,
  CartView,
  CartType,
} from '@src/routes'
import {
  DiscontinuedMaterialPricingDetailFragment,
  ProductPricingDetailFragment,
} from '@src/fragments/ProductPricing.generated'
import { CatalogType, ItemToQuote } from '@src/types/graphql-types'
import { sendBeginOrCompleteQuoteRequestEvent } from '@src/utils/analytics'
import { setQuickOrderAddToCartData } from '@utils/analytics/cartAndCheckoutEvents'
import { AddToCartPagesEnum } from '@utils/analytics/enums'
import { extractData } from '@utils/errorHandler'
import messages from '@utils/messages'
import { QuickOrderProvider } from '@src/components/QuickOrder/utils/quickOrderContext'
import {
  SitePreference,
  useCurrentUser,
  canUserRequestQuote,
} from '@utils/useCurrentUser'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import BulkUpload from './BulkUpload'
import ManualEntry from './ManualEntry'
import { useKoreaUser } from '@src/utils/useChinaUser'
import { useUserSession } from '@utils/useUserSession'
import { Link } from '@src/components/Link'
import clsx from 'clsx'
import { useGenerateQuoteMutation } from '@src/mutations/GenerateQuoteMutation.generated'
import { useCartView } from '../Cart/useCartView'
import { useLatest } from 'react-use'
import { isDefined } from '@src/utils/isDefined'
import { useQuickOrderPriceAvailability } from './useQuickOrderPriceAvailability'
import QuickOrderFormActions from './QuickOrderFormActions'
import QuickOrderTabs from '@src/components/QuickOrder/QuickOrderTabs'
import vrStyles from '@src/styles/utils/vrStyles'
import { fieldsetReset, legendReset } from '@src/styles/utils/formElementReset'
import { useMaterialsCache } from '@src/utils/useMaterialsCache'
import { getUpdatedPricingErrors } from '@src/utils/getUpdatedPricingErrors'
import { useGetMaterials } from '@src/routes/QuickOrder/useGetMaterials'
import { useAddMaterialsToCart } from './useAddMaterialsToCart'
import { useGlobalModal } from '@src/utils/useGlobalModal'
import RequestQuoteModalB2B from '@src/components/RequestQuoteModalB2B'

export interface QuickOrderFormValues {
  materials: MaterialsInput[]
  bulkUploadMaterials: string
}

export const defaultMaterial: MaterialsInput = {
  materialNumber: '',
  promoCode: '',
  quantity: 1,
}

export const defaultMaterials = [
  defaultMaterial,
  defaultMaterial,
  defaultMaterial,
  defaultMaterial,
  defaultMaterial,
]

const { vr2 } = vrStyles

const useStyles = makeStyles((theme: Theme) => ({
  quickOrderHeading: {
    paddingBottom: theme.spacing(6),
  },
  quickOrderWrap: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing(10),
    },
  },
  addIcon: {
    fontSize: theme.typography.pxToRem(18),
  },
  addRows: {
    cursor: 'pointer',
    paddingInline: 'initial',
    justifyContent: 'initial',
    '&.MuiButton-root.Mui-disabled': {
      cursor: 'auto',
      color: theme.palette.text.disabled,
    },
  },
  tabGrid: {
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(10),
    },
  },
  tabGridWrapper: {
    display: 'flex',
  },
  cannotReorderMessage: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '100%',
    border: `1px solid ${theme.palette.warning.main}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  warningIcon: {
    color: theme.palette.warning.main,
    margin: theme.spacing(0, 4),
  },
  link: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.primary.main,
    cursor: 'pointer',
    textDecoration: 'underline',
    '&:hover': {
      color: '#4f8ab8',
    },
  },
  sidebarGrid: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      marginTop: theme.spacing(12),
    },
  },
  sticky: {
    backgroundColor: theme.palette.common.white,
    bottom: 0,
    marginBottom: 12, // prevent "Get a Quote" section from being cut off in both tabs
    padding: '0 12px 16px !important', // match/override MuiGrid (this is only applied to mobile)
    position: 'sticky',
  },
  stickyShadow: {
    bottom: 74, // sticky height with one button (80) minus this element's height (6)
    boxShadow: '0 -3px 6px 0 rgba(0, 0, 0, 0.16)',
    height: 6, // tall enough to see the shadow spread
    margin: theme.spacing(0, -4), // extend beyond the edges of the grid container to full width
    position: 'sticky',
    zIndex: 1,
  },
  stickyShadowTall: {
    bottom: 122, // sticky height with two buttons (128) minus this element's height (6)
  },
  stickyShadowCover: {
    backgroundColor: theme.palette.common.white,
    bottom: 0,
    height: 10, // large enough to cover the shadow
    margin: theme.spacing(0, -4), // extend beyond the edges of the grid container to full width
    position: 'relative',
    zIndex: 2,
  },
  fieldset: {
    ...fieldsetReset(),
  },
  legend: {
    ...legendReset(),
  },
  vr2,
}))

const {
  publicRuntimeConfig: { featureFlags, brandIdentity },
} = getConfig()

interface QuickOrderProps {
  heading: React.ReactNode
  sidebar?: React.ReactNode
  classes?: {
    addToCartSection?: string
  }
  shouldRenderAddToCartButton?: boolean
  renderManualEntry?: () => JSX.Element
  manualEntryRows?: number
  onRequestQuote?: (tab: string, userId: string | undefined) => void
  onTabClick?: (tab: string, gaCookie: string | undefined) => void
  source?: string
}

const QuickOrder: React.FC<QuickOrderProps> = ({
  heading,
  sidebar,
  renderManualEntry,
  shouldRenderAddToCartButton = true,
  manualEntryRows = 3,
  onRequestQuote,
  onTabClick,
  source,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const router = useRouter()
  const isKoreaUser = useKoreaUser()
  const { userSession } = useUserSession()
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm')) === true
  const user = useCurrentUser()
  const { getMaterialsFromPricing, getMaterialsWithMarketPlaceData } =
    useGetMaterials()

  const {
    addMaterialsToCart,
    isLoading,
    addToCartData,
    addMarketplaceToCartData,
    cartError,
  } = useAddMaterialsToCart()

  const {
    getSitePreference,
    isDTAQZuCustomer,
    isMultiCountryUser,
    userIsLoggedIn,
    currentUser,
    userId,
    hasOnlyBlueERP,
    isB2BUser,
  } = user
  const shouldRenderRequestQuoteButton = canUserRequestQuote(user)
  const { setGlobalModalComponent, clearGlobalModalComponent } =
    useGlobalModal()
  const { setGlobalSnackbarState, setSnackbar } = useGlobalSnackbar()
  const [modalOpen, setModalOpen] =
    useState<ProductPricingDetailFragment | null>(null)
  const [pricingErrors, setPricingErrors] = useState<{
    [x: number]: {
      [productNumber: string]: DiscontinuedMaterialPricingDetailFragment
    }
  }>({ 0: {}, 1: {}, 2: {} })
  const { loadMaterialsData, materialsCache, latestMaterialsCache } =
    useMaterialsCache()
  const [materialsToAdd, setMaterialsToAdd] = useState<MaterialsInput[]>([])
  const materialsMarketplaceType = useRef<{ [key: string]: boolean }>({})
  const [validationErrors, setValidationErrors] = useState<string[]>([])
  const [submitting, setSubmitting] = useState(false)
  const submittingRef = useLatest(submitting)
  const [isRequestingQuote, setIsRequestingQuote] = useState<boolean>(false)
  const erpType = useRef<Object>({})
  const materialsLoading = Object.values(materialsCache).some((r) => r?.loading)
  const [
    getPricingForProduct,
    { data: validationMaterials, isLoading: isPricingLoading, pricingCache },
  ] = useQuickOrderPriceAvailability()
  const { cartType } = useCartView()
  const [generateQuote] = useGenerateQuoteMutation()
  const { validateBulkUpload } = useBulkUploadValidation('QuickOrder')

  const [tabValue, setTabValue] = useState(
    getSitePreference(SitePreference.ProductEntryPreference) === 'BULK_UPLOAD'
      ? 1
      : 0
  )

  const isDisableAddMoreRow =
    getSitePreference(SitePreference.NumberOfEntryFields) === 0 ? true : false

  const initialValues = {
    materials: Array(
      getSitePreference(SitePreference.NumberOfEntryFields) || manualEntryRows
    )
      .fill(0)
      .map(() => defaultMaterial),
    bulkUploadMaterials: '',
  }

  // specific to DTAQ customers only
  const showPreferredDealerSection =
    featureFlags.dealerCartEnabled && isDTAQZuCustomer
  const hasPreferredDealer = Boolean(
    getSitePreference(SitePreference.PreferredDealerId)
  )
  const isPreferredDealerRequired =
    showPreferredDealerSection && !hasPreferredDealer
  const [isDealerModalOpen, setIsDealerModalOpen] = useState(false)

  // Starts the process of submitting materials which may fail if materials are invalid
  const validateMaterialsAndRetrievePricing = async (
    values: QuickOrderFormValues,
    formikBag: FormikHelpers<QuickOrderFormValues>
  ) => {
    let materialValues: MaterialsInput[]
    if (tabValue === 0) {
      materialValues = values.materials
        .map((material) => (material.materialNumber ? material : null))
        .filter(isDefined)
    } else {
      materialValues = parseMaterialsInput(values.bulkUploadMaterials)
      formikBag.setFieldValue(
        'bulkUploadMaterials',
        formatQuickOrderBulkUploadEntry(materialValues)
      )
    }
    if (materialValues && materialValues.length > 0) {
      setMaterialsToAdd(materialValues)
      const { materialsDetailResult, updatedMaterialsCache } =
        await loadMaterialsData(materialValues)
      materialsDetailResult?.data?.getMaterialsDetail.forEach((material) => {
        if (!material) return
        materialsMarketplaceType.current[material.number] =
          !!material.isMarketplace
        erpType.current[material.number] = material.erpType
      })
      await getPricingForProduct(
        materialValues,
        updatedMaterialsCache || latestMaterialsCache.current
      )
    } else {
      setSubmitting(false)
      if (isRequestingQuote) {
        setIsRequestingQuote(false)
      }
    }
  }

  const hideAddToCartForPrepackItems = getSitePreference(
    SitePreference.HideAddToCartForPrepackItems
  )

  const disableQuoteButton = (
    formikBag: FormikProps<QuickOrderFormValues>,
    tabValue: number
  ): boolean => {
    switch (tabValue) {
      case 0: {
        return (
          formikBag.values.materials.filter(
            (material) => material.materialNumber
          ).length === 0 ||
          validationErrors.length !== 0 ||
          hideAddToCartForPrepackItems ||
          isMultiCountryUser
        )
      }
      case 1: {
        return (
          !formikBag.values.bulkUploadMaterials ||
          validationErrors.length !== 0 ||
          hideAddToCartForPrepackItems ||
          isMultiCountryUser
        )
      }
      default:
        return true
    }
  }

  const handleConvertQuickOrderToQuote = async (
    materials: MaterialsInput[]
  ) => {
    const materialsFromPricing = getMaterialsFromPricing(validationMaterials)

    const materialsWithMarketplaceData = getMaterialsWithMarketPlaceData(
      materials,
      materialsFromPricing
    )

    const mapMaterialsToQuoteInput: ItemToQuote[] = materialsWithMarketplaceData
      .filter((material) => material.catalogType !== CatalogType.Marketplace)
      .map((material) => {
        const { materialNumber, quantity } = material
        return {
          materialNumber,
          quantity,
        }
      })

    if (mapMaterialsToQuoteInput && mapMaterialsToQuoteInput.length > 0) {
      try {
        const { data } = await generateQuote({
          variables: { input: mapMaterialsToQuoteInput },
        })
        if (data) {
          router.replace(
            cartRoute[CartView.QuoteConfirmation](
              cartType,
              data.generateQuote.orderNumber
            )
          )
        }
      } catch (error) {
        setSnackbar(SnackbarMessages.QuickOrderConvertToQuoteError, error)
        return
      }
    }
    setSubmitting(false)
    setIsRequestingQuote(false)
  }

  // Check if all materials entered are valid and if so add them all to cart
  useEffect(() => {
    const updatedPricingErrors = getUpdatedPricingErrors(
      materialsToAdd,
      pricingCache,
      materialsCache
    )
    const preferredDealerId = getSitePreference(
      SitePreference.PreferredDealerId
    )

    if (isDTAQZuCustomer && preferredDealerId) {
      materialsToAdd.forEach((material) => {
        material.dealerId = preferredDealerId
      })
    }

    if (!currentUser?.metadata?.isMarketplaceCartEnabled) {
      materialsToAdd.forEach((material) => {
        const isMarketplace =
          materialsMarketplaceType.current[material.materialNumber]
        if (isMarketplace) {
          updatedPricingErrors[material.materialNumber] = {
            errorMsg: 'IS_NOT_A_VALID_PRODUCT_NUMBER',
            values: { sku: material.materialNumber },
          }
        }
      })
    }

    if (isRequestingQuote) {
      materialsToAdd.forEach((material) => {
        const isMarketplace =
          materialsMarketplaceType.current[material.materialNumber]
        if (isMarketplace) {
          updatedPricingErrors[material.materialNumber] = {
            errorMsg: 'CANT_REQUEST_QUOTE_WITH_MARKETPLACE_ITEMS',
            values: { sku: material.materialNumber },
          }
        }
      })
    }

    const hasPricingErrors = Object.keys(updatedPricingErrors).length > 0
    const validationMaterialsLoading = isPricingLoading()
    if (
      !materialsLoading &&
      !validationMaterialsLoading &&
      !hasPricingErrors &&
      submittingRef.current &&
      materialsToAdd.length > 0
    ) {
      if (isB2BUser && isRequestingQuote) {
        setSubmitting(false)
        return setGlobalModalComponent(
          <RequestQuoteModalB2B
            quoteData={materialsToAdd}
            onClose={clearGlobalModalComponent}
          />
        )
      }
      if (isRequestingQuote) {
        handleConvertQuickOrderToQuote(materialsToAdd)
      } else {
        addMaterialsToCart(materialsToAdd, validationMaterials)
      }
    } else if (!validationMaterialsLoading && !materialsLoading) {
      setSubmitting(false)
      setIsRequestingQuote(false)
    }
    setPricingErrors({ ...pricingErrors, [tabValue]: updatedPricingErrors })
    // TODO: need a test before we refactor these dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationMaterials, isPricingLoading, materialsLoading])

  const handleSubmit = async (
    values: QuickOrderFormValues,
    formikBag: FormikHelpers<QuickOrderFormValues>
  ) => {
    isPreferredDealerRequired ? setIsDealerModalOpen(true) : setSubmitting(true)
    validateMaterialsAndRetrievePricing(values, formikBag)
  }

  // Finally redirect to cart on successful addToCartMutation
  //display error when addToCart fails
  useEffect(() => {
    if (
      !isLoading &&
      (addToCartData?.addItemsToCart ||
        addMarketplaceToCartData?.addItemsToCart)
    ) {
      setQuickOrderAddToCartData(
        validationMaterials?.getPricingForMultipleProducts || [],
        AddToCartPagesEnum.QuickOrder,
        erpType.current
      )
      if (!renderManualEntry || tabValue !== 0) {
        router.replace(
          hasOnlyBlueERP
            ? cartRoute[CartView.Cart](CartType.BlueCart)
            : cartRoute[CartView.Cart]()
        )
        return
      }
    }
    setSubmitting(false)
    if (!isLoading && cartError) {
      if (!cartError) {
        return
      }
      const { displayableError, hasError } = extractData(cartError)
      if (
        hasError('CANNOT_ADD_OTHER_PRODUCT_TYPE_WITH_DIGITAL_PRODUCTS') ||
        hasError('CANNOT_ADD_DIGITAL_PRODUCT_WITH_OTHERS') ||
        hasError('CANNOT_CONTAIN_OTHER_PRODUCT_TYPE_WITH_DIGITAL_PRODUCTS')
      ) {
        return setSnackbar(SnackbarMessages.DigitalProductCartMixingError)
      }
      setGlobalSnackbarState({
        open: true,
        message: displayableError || (
          <FormattedMessage {...messages.GENERIC_ERROR} />
        ),
        variant: SnackbarType.Error,
      })
    }
    // TODO: need a test before we refactor these dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addToCartData?.addItemsToCart,
    cartError,
    addMarketplaceToCartData?.addItemsToCart,
  ])

  //This is applicable only to South Korea users. Refer, STRAT-18383.Show message to Korea users who don't have ordering ability
  const { actiondone = '' } = router.query ? router.query : {}
  const koreaExtendedRegistrationLink = (
    <FormattedMessage {...messages.NEW_CUSTOMER_UPDATE_KOREA_LINK}>
      {(text) => (
        <Link
          {...staticContent.index({
            path: '/life-science/ecommerce/new-account',
            language: userSession.language,
            country: userSession.country,
          })}
          passHref
        >
          <MUILink
            color="textSecondary"
            target="_blank"
            className={classes.link}
          >
            {text}
          </MUILink>
        </Link>
      )}
    </FormattedMessage>
  )
  useEffect(() => {
    if (actiondone === 'basic-registration' && isKoreaUser && userIsLoggedIn) {
      let messageBody = (
        <FormattedMessage
          {...messages.BASIC_REGISTRATION_COMPLETE_KOREA}
          values={{
            link: koreaExtendedRegistrationLink,
          }}
        />
      )
      if (brandIdentity === 'MerckMillipore') {
        messageBody = (
          <FormattedMessage
            id="BASIC_REGISTRATION_COMPLETE_KOREA_MM"
            defaultMessage="Basic web registration has been completed. To complete full registration to make purchase order, please contact Customer Support Team: RACS@merckgroup.com/02-2185-3840."
          />
        )
      }
      setSnackbar(SnackbarMessages.BasicRegistrationCompleteKorea, messageBody)
    } else if (
      actiondone === 'order-fail-kr' &&
      isKoreaUser &&
      userIsLoggedIn
    ) {
      const messageBody = (
        <FormattedMessage
          {...messages.ORDER_CANNOT_PROCESSED_KOREA}
          values={{
            link: koreaExtendedRegistrationLink,
          }}
        />
      )
      setSnackbar(SnackbarMessages.OrderFailedCompleteRegistration, messageBody)
    }
  }, [actiondone])

  const handleRequestAQuote = (
    formikBag: FormikProps<QuickOrderFormValues>,
    trackingEventAction: string
  ) => {
    setIsRequestingQuote(true)
    // This allows the Request Quote button to submit the form when both the Request Quote and Add to Cart buttons are present
    // The shouldRenderAddToCartButton conditional prevents a double submit from happening when only the Request Quote is present
    // See QuickOrderFormActions for logic on when each button is type=submit vs type=button
    shouldRenderAddToCartButton && formikBag.submitForm()
    if (onRequestQuote) {
      onRequestQuote(trackingEventAction, userId)
    } else {
      sendBeginOrCompleteQuoteRequestEvent(
        {
          event: 'complete_quote_request',
          detail: trackingEventAction,
          section: 'quick order',
          component: 'list',
          elementType: 'link',
          elementText: 'request quote',
        },
        {
          eventCategory: 'quick order page',
          eventAction: `request a quote - ${trackingEventAction}`,
          eventLabel: userId,
          eventInteractionType: 0,
        }
      )
    }
  }

  const renderMultipleRowManualEntry = (
    formikBag: FormikProps<QuickOrderFormValues>
  ) => (
    <Form>
      <fieldset className={classes.fieldset}>
        <legend className={clsx(classes.legend, classes.vr2)}>
          <Typography variant="body1" component="span">
            {isDisableAddMoreRow ? (
              <FormattedMessage
                id="MANUAL_ENTRY_INSTRUCTIONS_QUICK_ORDER_NOT_AVAILABLE"
                defaultMessage="Quick Order is not available for your profile. If you have any questions please contact the web help desk."
              />
            ) : (
              <FormattedMessage
                id="MANUAL_ENTRY_INSTRUCTIONS"
                defaultMessage="Enter the product SKU (product number and pack size) and quantity for each item you would like to order."
              />
            )}
          </Typography>
        </legend>
        <Grid container justifyContent="space-between" spacing={6}>
          <ManualEntry
            formikBag={formikBag}
            pricingErrors={pricingErrors[0]}
            pricing={pricingCache.current}
            materials={materialsCache}
            setModalOpen={setModalOpen}
            onBlur={() =>
              validateMaterialsAndRetrievePricing(formikBag.values, formikBag)
            }
            source={source}
          />
          <Grid item xs={12} sm={'auto'}>
            <Button
              size={isDesktop ? 'medium' : 'large'}
              className={classes.addRows}
              disabled={isDisableAddMoreRow}
              variant="text"
              color="primary"
              onClick={() =>
                formikBag.setFieldValue('materials', [
                  ...formikBag.values.materials,
                  ...defaultMaterials,
                ])
              }
              id="quick-order-add-five-more-rows"
            >
              <AddIcon className={classes.addIcon} />
              <FormattedMessage {...messages.ADD_FIVE_MORE_ROWS} />
            </Button>
          </Grid>
          {formikBag.values.materials.length < 50 && (
            <Grid
              item
              xs={12}
              sm={'auto'}
              className={clsx(!isDesktop && classes.sticky)}
            >
              {!isDesktop && (
                <>
                  <div className={classes.stickyShadowCover}></div>
                  <div
                    className={clsx(
                      classes.stickyShadow,
                      shouldRenderRequestQuoteButton && classes.stickyShadowTall
                    )}
                  ></div>
                </>
              )}
              <QuickOrderFormActions
                shouldRenderRequestQuoteButton={shouldRenderRequestQuoteButton}
                isRequestQuoteDisabled={disableQuoteButton(formikBag, tabValue)}
                shouldRenderAddToCartButton={shouldRenderAddToCartButton}
                isAddToCartDisabled={
                  formikBag.values.materials.filter(
                    (material) => material.materialNumber
                  ).length === 0 ||
                  submitting ||
                  hideAddToCartForPrepackItems ||
                  isMultiCountryUser
                }
                onClickRequestAQuote={() => {
                  handleRequestAQuote(formikBag, 'manual entry')
                }}
              />
            </Grid>
          )}
        </Grid>
      </fieldset>
    </Form>
  )

  const helperText = (
    <FormattedMessage
      id="QUICK_ORDER_HELPER_TEXT"
      defaultMessage="*Promo codes and references numbers cannot be used when requesting quotes."
    />
  )

  const renderBulkUpload = (formikBag: FormikProps<QuickOrderFormValues>) => (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Form>
          <Grid container justifyContent="flex-end" spacing={6}>
            <Grid item xs={12}>
              <BulkUpload
                formikBag={formikBag}
                validationErrors={validationErrors}
                setValidationErrors={setValidationErrors}
                pricingErrors={pricingErrors[1]}
                setModalOpen={setModalOpen}
                pricing={pricingCache.current}
                parseSpreadsheet={parseQuickOrderSpreadsheet}
                validate={validateBulkUpload}
                dlType="quick order template"
                fileName="quick_order_template.csv"
                formatEntry={formatQuickOrderBulkUploadEntry}
                helperText={helperText}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              sm={'auto'}
              className={clsx(!isDesktop && classes.sticky)}
            >
              {!isDesktop && (
                <>
                  <div className={classes.stickyShadowCover}></div>
                  <div
                    className={clsx(
                      classes.stickyShadow,
                      shouldRenderRequestQuoteButton && classes.stickyShadowTall
                    )}
                  ></div>
                </>
              )}
              <div>
                <QuickOrderFormActions
                  shouldRenderRequestQuoteButton={
                    shouldRenderRequestQuoteButton
                  }
                  isRequestQuoteDisabled={disableQuoteButton(
                    formikBag,
                    tabValue
                  )}
                  shouldRenderAddToCartButton={shouldRenderAddToCartButton}
                  isAddToCartDisabled={
                    !formikBag.values.bulkUploadMaterials ||
                    validationErrors.length !== 0 ||
                    submitting ||
                    hideAddToCartForPrepackItems ||
                    isMultiCountryUser
                  }
                  onClickRequestAQuote={() => {
                    handleRequestAQuote(formikBag, 'bulk upload')
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </Form>
      </Grid>
    </Grid>
  )

  return (
    <QuickOrderProvider>
      <Formik<QuickOrderFormValues>
        enableReinitialize
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {(formikBag) => (
          <>
            <div className={classes.quickOrderHeading}>{heading}</div>
            <Grid container className={classes.quickOrderWrap}>
              {hideAddToCartForPrepackItems && (
                <Grid item xs={12}>
                  <div className={classes.cannotReorderMessage}>
                    <WarningIcon
                      fontSize="large"
                      className={classes.warningIcon}
                    />
                    <FormattedMessage
                      id="CANNOT_ADD_TO_CART_DETAILS"
                      defaultMessage="Your profile is currently configured to allow ordering for configurable items such as oligos and peptides but not regular inventory items. Although you can access pricing and availability for these products, they cannot be added to your shopping cart."
                    />
                  </div>
                </Grid>
              )}

              <div className={classes.tabGridWrapper}>
                <Grid
                  item
                  xs={12}
                  md={sidebar ? 8 : 12}
                  className={classes.tabGrid}
                >
                  <QuickOrderTabs
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    manualEntryForm={
                      <Grid container item xs={12}>
                        <Grid item xs={12}>
                          {renderManualEntry
                            ? renderManualEntry()
                            : renderMultipleRowManualEntry(formikBag)}
                        </Grid>
                      </Grid>
                    }
                    bulkUploadForm={renderBulkUpload(formikBag)}
                    onTabClick={onTabClick}
                  />
                </Grid>

                {/* Side menu */}
                {sidebar && (
                  <Grid item md={4} className={classes.sidebarGrid}>
                    {sidebar}
                  </Grid>
                )}
              </div>

              {submitting && <CircleLoader />}
              {modalOpen?.productNumber &&
                modalOpen?.discontinuedPricingInfo && (
                  <ReplacementProductsModal
                    open={!!modalOpen}
                    setOpen={setModalOpen}
                    productNumber={modalOpen?.productNumber}
                    discontinuedPricingInfo={modalOpen.discontinuedPricingInfo}
                    hideCompare
                    addToList={(replacementMaterials: MaterialsInput[]) => {
                      if (tabValue === 0) {
                        const newValues = formikBag.values.materials
                          .map((material) =>
                            material.materialNumber &&
                            material.materialNumber !== modalOpen?.productNumber
                              ? material
                              : null
                          )
                          .filter((n) => n)
                          .concat(replacementMaterials)
                        newValues.forEach((value, index) => {
                          formikBag.setFieldValue(
                            `materials[${index}].materialNumber`,
                            value?.materialNumber
                          )
                          formikBag.setFieldValue(
                            `materials[${index}].quantity`,
                            value?.quantity
                          )
                        })
                        setModalOpen(null)
                      } else {
                        const parsedBulkMaterials = parseMaterialsInput(
                          formikBag.values.bulkUploadMaterials
                        )
                        const newBulkMaterials = parsedBulkMaterials
                          .filter(
                            (mat) =>
                              mat.materialNumber !== modalOpen?.productNumber
                          )
                          .concat(
                            replacementMaterials.map((replacementMat) => ({
                              materialNumber: replacementMat.materialNumber,
                              quantity: replacementMat.quantity,
                            }))
                          )
                        formikBag.setFieldValue(
                          'bulkUploadMaterials',
                          formatQuickOrderBulkUploadEntry(newBulkMaterials)
                        )
                        const newPricingErrors = {}
                        Object.keys(pricingErrors[tabValue]).forEach((key) => {
                          if (key !== modalOpen.productNumber) {
                            newPricingErrors[key] = pricingErrors[tabValue][key]
                          }
                        })
                        setPricingErrors({
                          ...pricingErrors,
                          [tabValue]: newPricingErrors,
                        })
                        setModalOpen(null)
                      }
                    }}
                  />
                )}
              {showPreferredDealerSection && (
                <SelectDtaqDealerModal
                  btnTextOverride={messages.SET_AS_PREFERRED_DEALER}
                  open={isDealerModalOpen}
                  onClose={() => setIsDealerModalOpen(false)}
                  onDealerSelectCallback={
                    isPreferredDealerRequired
                      ? formikBag.submitForm
                      : () => null
                  }
                  onSearch={() =>
                    console.warn('🚧: Feature under construction')
                  }
                  disabled={false}
                />
              )}
            </Grid>
          </>
        )}
      </Formik>
    </QuickOrderProvider>
  )
}

export default QuickOrder
